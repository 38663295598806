// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require('bootstrap')

import '../stylesheets/application';

import $ from 'jquery';
import 'select2';
import 'cocoon-js';
import 'jquery-jpostal-ja'

$(document).on('turbolinks:load', function() {
  $('.searchable').select2({theme: 'bootstrap4'});
  $("[id$='_zipcode']").jpostal({
    postcode : ["[id$='_zipcode']"],
    address : {
      "[id$='_pref']"  : '%3',
      "[id$='_city']"  : '%4',
      "[id$='_street']"  : '%5'
    }
  });

  $('#user_identity_verification_documents')
    .on("cocoon:before-remove", function(event, insertedItem) {
      console.log($('.identity-verification-document').length)
      if ($('.identity-verification-document').length <= 1) {
        event.preventDefault();
      }
    })
    .on('cocoon:after-remove', function(event, insertedItem) {
      if ($('.identity-verification-document').length <= 1) {
        event.preventDefault();
      }
    });
})
